import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';

// Constants
const ProductionURL = 'https://backend.estate.hart-digital.com';
const StageURL = 'https://backend-stage.estate.hart-digital.com'
const DelayAfterLoad = 500;

const searchParams = new URLSearchParams(document.location.search);
const planId = searchParams.get('id');
const crmPlanId = searchParams.get('crmPlanId');
const env = searchParams.get('env') || 'production';
const primaryCameraPointId = searchParams.get('primaryCameraPointId');

const apiUrl = env === 'production' ? ProductionURL : StageURL;

const handleWidgetBranding = async (branding) => {
  if (branding == null) {
    return {};
  }

  const { company_name, widget_style_path, widget_params } = branding;

  if (company_name != null) {
    document.title = company_name;
  }

  if (widget_style_path != null) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = widget_style_path;
    document.head.appendChild(link);
  }

  if (widget_params != null) {
    const keys = Object.keys(widget_params).filter((key) => ![
      'logo',
      'logoUrl',
      'locale',
      'API_URL',
      'width',
      'height',
      'json',
      'branding',
      'floors',
      'primaryCameraPointId',
    ].includes(key));
    const keysCount = keys.length;

    const result = {};

    for (let i = 0; i < keysCount; i++) {
      const key = keys[i];
      result[key] = widget_params[key];
    }

    return result;
  }

  return {};
};

const createWidget = async () => {
  const apiHandler = new ApiStore(apiUrl);

  const planData = crmPlanId
    ? await apiHandler.loadCrmWidgetData(crmPlanId)
    : await apiHandler.loadWidgetData(planId);
  // const planData = await import('./mock.json');

  const brandingResult = await handleWidgetBranding(planData.raw.widget_branding);

  const options = {
    ...planData.parsed,
    API_URL: apiUrl,
    rotationMode: rotationModes.DEFAULT,
    tabs: ['panorama', 'rotation'],
    integrations: {
      sentry: {
        dsn: 'https://62f653a9fc5368353dd83fba4017f1dd@sentry.hart-digital.com/16',
      },
    },
    primaryCameraPointId,
    ...brandingResult,
  };

  new Widget('#widget', options);
};

const hidePreloader = () => {
  const preloader = document.querySelector('.widget-preloader-frame');
  if (preloader) preloader.classList.add('hidden');
};

createWidget().then(() => setTimeout(hidePreloader, DelayAfterLoad));
